const api_link = "https://routes.dfcapitals.com/admin";
const access_key =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381.77004ea213d5fc71acf74a8c9c6795fb";

let access = {
  sr: localStorage.getItem("server"),
  jwt: localStorage.getItem("token"),
  a_id: localStorage.getItem("adminsId"),
};

export async function apiCall(path, data) {
  try {
    const response = await fetch(api_link + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: { ...data, ...access },
        access_key: access_key,
      }),
    });

    console.log(data);

    const jsonResponse = await response.json();
    console.log(jsonResponse, "res");

    if (jsonResponse.error === "False") {
      return jsonResponse;
    } else if (jsonResponse.status === "Logout") {
      localStorage.clear();
      window.location.href = "/";
      return;
    } else {
      alert(jsonResponse.message); // Return the parsed JSON response
    }
  } catch (error) {
    console.error("API call failed:", error, "-", path); // Log any errors
    throw error; // Rethrow the error to be handled by the calling function
  }
}
// }
