import React from "react";
import ClientDetailsCard from "../ClientDetailsCard";
import { FaUser, FaUserCheck, FaChartPie } from "react-icons/fa";
import { COLORSCHEMEA } from "../../../../ColorSchema/ColorSchema";
import { MdAccountBalance } from "react-icons/md";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import ToggleCard from "./ToggleCard";
import { Button } from "primereact/button";
import { apiCall } from "../../../../Backend/Backend";
import moment from "moment";
import { useNotification } from "../../../Notification/NotificationContext";
import NumberFormatIn from "../../../Common/NumberFormatIn";
import LiveSummaryModal from "./Modal/LiveSummaryModal";
import styled from "styled-components";
import BalanceModal from "./Modal/BalanceModal";
import CreditModal from "./Modal/CreditModal";

const ResponsiveDiv = styled.div`
  display: flex;
  height: calc(100vh - 135px);
  width: 100%;
  /* padding: 10px; */

  /* Default layout: row direction */
  flex-direction: row;

  /* Media query for screen widths less than 1000px */
  @media (max-width: 1000px) {
    flex-direction: column;
    overflow-y: scroll; /* Makes the container scrollable if content overflows */
    padding: 0px;
    padding-left: 0px;
  }
`;

const StyledDiv = styled.div`
  border-style: solid;
  height: 100%;
  border-width: 1px;
  border-color: ${(props) => props.borderColor || COLORSCHEMEA.THEMEFONT};
  border-radius: 5px;
  flex: 1;

  @media (max-width: 1000px) {
    /* margin: 10px; */
    &:last-child {
      margin-top: 10px;
    }
  }

  @media (min-width: 1001px) {
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export default function NewBasic({ clientData, reload }) {
  const [loading, setLoading] = React.useState(true);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [overviewClientData, setOverviewClientData] = React.useState([]);
  const { addNotification } = useNotification();

  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] =
    React.useState(false);
  const [visibleBalanceModal, setVisibleBalanceModal] = React.useState(false);
  const [visibleCreditModal, setVisibleCreditModal] = React.useState(false);
  const [position, setPosition] = React.useState("top");

  const showBalanceModal = (position) => {
    setPosition("top");
    setVisibleBalanceModal(true);
  };
  const showCreditModal = (position) => {
    setPosition("top");
    setVisibleCreditModal(true);
  };

  const details =
    overviewClientData?.fatak !== "" ? (
      <>
        {"Notify Over "}
        <NumberFormatIn value={overviewClientData?.fatak} />
        {" Limit"}
      </>
    ) : null;

  React.useEffect(() => {
    load_users_overview();
  }, []);

  const load_users_overview = async () => {
    let sendData = {
      m_id: clientData.id,
    };

    const response = await apiCall("/load_master_overview", sendData);
    setLoading(false);
    setOverviewClientData(response.masters_overview[0]);
    setButtonsData(response.buttons_data);
  };

  const change_masters_setting = async (key) => {
    let sendData = {
      m_id: clientData.id,
      button_key: key,
    };
    setLoading(true);
    const response = await apiCall("/change_masters_setting", sendData);

    load_users_overview();
    if (response.on_off == "0") {
      addNotification(response.message, "error");
    } else {
      addNotification(response.message, "success");
    }
    setLoading(false);
  };

  const change_masters_status = async () => {
    let sendData = {
      m_id: clientData.id,
    };
    setLoading(true);
    const response = await apiCall("/change_masters_status", sendData);

    load_users_overview();
    if (response.on_off == "0") {
      addNotification(response.message, "error");
    } else {
      addNotification(response.message, "success");
    }
    setLoading(false);
  };

  const add_users_lock = async () => {
    if (overviewClientData?.block_minute == "") {
      var selection = parseInt(window.prompt("Enter Minutes To Lock"));
      if (
        isNaN(parseFloat(selection)) ||
        parseFloat(selection) == null ||
        parseFloat(selection) == "" ||
        parseFloat(selection) == undefined
      ) {
        window.alert("Only Number Are Allowed");
        return;
      }
      let sendData = {
        c_id: overviewClientData.id,
        lock_time: selection,
      };
      setLoading(true);
      const response = await apiCall("/add_users_lock", sendData);

      load_users_overview();
      if (response.on_off == "0") {
        addNotification(response.message, "error");
      } else {
        addNotification(response.message, "success");
      }
      setLoading(false);
      return;
    }
  };

  const cancel_users_lock = async () => {
    if (overviewClientData?.block_minute != "") {
      let check = window.confirm(
        "Are you sure want to unlock trades for client ?"
      );
      if (check) {
        let sendData = {
          c_id: overviewClientData.id,
        };
        setLoading(true);
        const response = await apiCall("/cancel_users_lock", sendData);

        load_users_overview();
        if (response.on_off == "0") {
          addNotification(response.message, "error");
        } else {
          addNotification(response.message, "success");
        }
        setLoading(false);
        return;
      }
    }
  };

  const cancel_users_fatak = async () => {
    if (overviewClientData?.fatak > 0) {
      let check = window.confirm(
        "Are you sure want to Reset client Boundary ?"
      );
      if (check) {
        if (!loading) {
          let sendData = {
            c_id: overviewClientData.id,
          };
          setLoading(true);
          const response = await apiCall("/cancel_users_fatak", sendData);

          load_users_overview();
          if (response.on_off == "0") {
            addNotification(response.message, "error");
          } else {
            addNotification(response.message, "success");
          }
          setLoading(false);
          return;
        }
      }
    }
  };

  const add_users_fatak = async () => {
    if (overviewClientData?.fatak <= 0) {
      var selection = parseInt(
        window.prompt("Enter Limit For Boundary To Trigger")
      );
      if (
        isNaN(parseFloat(selection)) ||
        parseFloat(selection) == null ||
        parseFloat(selection) == "" ||
        parseFloat(selection) == undefined
      ) {
        window.alert("Only Number Are Allowed");
      } else {
        if (!loading) {
          let sendData = {
            c_id: overviewClientData.id,
            fatak: selection,
          };
          setLoading(true);
          const response = await apiCall("/add_users_fatak", sendData);

          load_users_overview();
          if (response.on_off == "0") {
            addNotification(response.message, "error");
          } else {
            addNotification(response.message, "success");
          }
          setLoading(false);
          return;
        }
      }
    }
  };

  return (
    <ResponsiveDiv>
      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <div
          style={{
            height: 70,
            display: "flex",
            alignItems: "center",
            paddingLeft: 15,
            justifyContent: "flex-start",
            borderBottomStyle: "solid",
            borderWidth: 0.4,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div style={{ fontSize: 18, fontWeight: "bold" }}>
              Basic Details
            </div>
            <div style={{ fontSize: 12, textTransform: "uppercase" }}>
              {clientData?.username}
            </div>
          </div>
          <div>
            <Button
              size="small"
              label="Live Summary"
              severity="primary"
              outlined
              onClick={() => setVisibleLiveSummaryModal(true)}
            />
          </div>
        </div>

        <ClientDetailsCard
          title={"Username"}
          details={overviewClientData?.username}
          Icon={FaUser}
        />
        <ClientDetailsCard
          title={"Name"}
          details={overviewClientData?.name}
          subDetails={moment(overviewClientData?.date_created).format(
            "DD-MMM-YY"
          )}
          Icon={FaUserCheck}
        />
        <ClientDetailsCard
          title={"Weekly Profitloss"}
          Icon={FaChartPie}
          details={overviewClientData?.weekly_pl}
          color={
            parseFloat(overviewClientData?.weekly_pl) >= 0
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
        />
        <ClientDetailsCard
          title={"Margin"}
          Icon={MdAccountBalance}
          details={<NumberFormatIn value={overviewClientData?.margin} />}
        />
        <ClientDetailsCard
          title={"Rent"}
          Icon={MdAccountBalance}
          details={<NumberFormatIn value={overviewClientData?.rent} />}
        />
        <ClientDetailsCard
          title={"Trade Margin"}
          Icon={MdAccountBalance}
          details={<NumberFormatIn value={overviewClientData?.trade_margin} />}
        />
        {/* <ClientDetailsCard
          title={"Min Brokerage"}
          Icon={MdAccountBalance}
          details={<NumberFormatIn value={overviewClientData?.min_brokerage} />}
        /> */}
        <ClientDetailsCard
          title={"Script Margin"}
          details={
            <NumberFormatIn value={overviewClientData?.script_wise_margin} />
          }
          Icon={RiMoneyRupeeCircleFill}
        />
      </StyledDiv>

      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <div
          style={{
            height: 70,
            display: "flex",
            justifyContent: "center",
            paddingLeft: 15,
            flexDirection: "column",
            alignItems: "flex-start",
            borderBottomStyle: "solid",
            borderWidth: 0.4,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            Account Settings
          </div>
          <div style={{ fontSize: 12 }}>Control User Limitations</div>
        </div>

        <ToggleCard
          onClick={() => change_masters_status()}
          name={"Status"}
          value={overviewClientData?.status == 1 ? true : false}
          details={overviewClientData?.status == 1 ? "Active" : "Inactive"}
          detailsColor={
            overviewClientData?.status == 1
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
        />
        {/* <ToggleCard
          name={"Trade Lock"}
          value={overviewClientData?.block_minute != ""}
          onClick={() =>
            overviewClientData?.block_minute == ""
              ? add_users_lock()
              : cancel_users_lock()
          }
          details={
            overviewClientData?.block_minute != "" &&
            "Active For " + overviewClientData?.block_minute + " Minutes"
          }
        /> */}
        {/* <ToggleCard
          name={"Boundary"}
          value={overviewClientData?.fatak != ""}
          onClick={() =>
            overviewClientData?.fatak == ""
              ? add_users_fatak()
              : cancel_users_fatak()
          }
          details={details}
        /> */}

        {buttonsData &&
          buttonsData.map((button, index) => (
            <ToggleCard
              key={index}
              color={button.color}
              onClick={() => change_masters_setting(button.id)} // Add the onClick handler
              value={button.active}
              name={button.text}
            />
          ))}
      </StyledDiv>

      {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={overviewClientData?.id}
          usersId={overviewClientData?.id}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          deposit={overviewClientData?.deposit}
          credit={overviewClientData?.credit}
          blocked_limit={overviewClientData?.blocked_limit}
          weekly_pl={overviewClientData?.weekly_pl}
          clientUsername={overviewClientData?.username}
        />
      ) : null}

      {visibleBalanceModal ? (
        <BalanceModal
          key={overviewClientData?.id}
          usersId={overviewClientData?.id}
          setVisible={() => setVisibleBalanceModal(false)}
          position={position}
          visible={visibleBalanceModal}
          deposit={overviewClientData?.deposit}
          clientUsername={overviewClientData?.username}
          reload={() => {
            load_users_overview();
            reload();
          }}
        />
      ) : null}

      {visibleCreditModal ? (
        <CreditModal
          key={overviewClientData?.id}
          usersId={overviewClientData?.id}
          setVisible={() => setVisibleCreditModal(false)}
          position={position}
          visible={visibleCreditModal}
          credit={overviewClientData?.credit}
          clientUsername={overviewClientData?.username}
          reload={() => {
            load_users_overview();
            reload();
          }}
        />
      ) : null}
    </ResponsiveDiv>
  );
}
