import React from "react";
import { NewTheme } from "../Theme/Theme";
import { apiCall } from "../../Backend/Backend";
// const backend = new Backend();

export default function Marquee(props) {
  const [marquee, setMarquee] = React.useState([]);

  React.useEffect(() => {
    load_marquee();
  }, []);

  const load_marquee = async () => {
    const response = await apiCall("/load_marquee");
    console.log(response);
    setMarquee(response?.marquee);
  };

  return marquee.length > 0 ? (
    <marquee scrollamount={5}>
      <div style={{ display: "flex", height: 20 }}>
        {marquee.map((i, t) => (
          <div key={t} style={{ color: "black", marginLeft: 20 }}>
            {t + 1}.
            <span style={{ color: NewTheme.MainColor, fontWeight: "bold" }}>
              {i.news_type}
            </span>{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>{i.news}</span>
          </div>
        ))}
      </div>
    </marquee>
  ) : null;
}
